<template>
  <div class="content">
    <h1 class="content__title title title--theme">Мои заявки на мероприятия</h1>
    <div class="app-list">
      <div
        type="button"
        class="app-list__item"
        @click="onItemClick(item.id)"
        v-for="item in list"
        :key="item.id"
      >
        <div class="app-list__title">{{ item.event_name }}</div>
        <div class="app-list__subt">{{ item.shift_name }}</div>
        <div class="app-list__text">
          <span>{{ item.shift_date_start_app }}</span> - <span>{{ item.shift_date_end_app }}</span>
        </div>
        <div class="app-list__text">
          <span>Статус заявки: </span> <span class="app-list__status">{{ item.status_name }}</span>
        </div>
      </div>
    </div>
    <paginate
      v-if="pagination.pages > 1"
      v-model="pagination.page"
      :page-count="pagination.pages"
      :clickHandler="clickPaginationCallback"
      :prev-text="'<'"
      :next-text="'>'"
      :container-class="'pagination'"
      :page-class="'pagination__item'"
      :page-link-class="'pagination__link'"
      :prev-class="'pagination__prev'"
      :next-class="'pagination__next'"
      :next-link-class="'pagination__link-next'"
      :prev-link-class="'pagination__link-prev'"
    >
    </paginate>
  </div>
</template>

<script>
export default {
  name: 'MyAppsList',
  data() {
    return {
      list: [],
      pagination: {
        pages: 1,
        page: 1,
        count: 0,
        prevLink: '',
        nextLink: ''
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(page = 1) {
      this.$store.dispatch('events/applications/GET_MY_LIST', page)
        .then(response => {
          this.list = response.data.results
          this.pagination.pages = response.data.pages
          this.pagination.count = response.data.count
        })
    },
    onItemClick(id) {
      this.$router.push({ name: 'my-app-edit', params: { id } })
    },
    clickPaginationCallback(page) {
      this.getData(page)
    },
  }
}
</script>
